import { render, staticRenderFns } from "./SkeletonTripCollectionCard.vue?vue&type=template&id=643aa58d&scoped=true"
import script from "./SkeletonTripCollectionCard.vue?vue&type=script&lang=js"
export * from "./SkeletonTripCollectionCard.vue?vue&type=script&lang=js"
import style0 from "./SkeletonTripCollectionCard.vue?vue&type=style&index=0&id=643aa58d&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "643aa58d",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
installComponents(component, {VCard,VCardText})
