<template>
  <v-container class="quote-form-summary">
    <CTable :headers="headers" :items="formattedData">
      <template #items="props">
        <td>{{ props.item.tripIndex }}</td>
        <td class="text-xs-left">
          {{ props.item.passengerCount }}
        </td>
        <td class="text-xs-left">
          {{ props.item.requiredDrivers }}
        </td>
        <td class="text-xs-left">
          {{ props.item.requiredVehicleCount }}
        </td>
        <td class="text-xs-left">
          {{ props.item.requiredVehicles }}
        </td>
        <td class="text-xs-left">
          {{ props.item.tripDueToday }}
        </td>
        <td class="text-xs-left">
          {{ props.item.tripSubtotal }}
        </td>
        <td class="text-xs-left">
          {{ props.item.tripFees }}
        </td>
        <td class="text-xs-left">
          {{ props.item.tripTotal }}
        </td>
      </template>
    </CTable>
  </v-container>
</template>

<script>
import { currencyFilter } from '@/utils/currency'

import CTable from '@/components/CTable.vue'

export default {
  components: {
    CTable,
  },
  props: {
    trips: { type: Array, default: () => [] },
    vehicleTypes: { type: Array, default: () => [] },
  },
  data() {
    return {
      headers: [
        { text: 'Trip', value: 'tripIndex' },
        { text: 'Passengers', value: 'passengerCount' },
        { text: 'Drivers', value: 'requiredDrivers' },
        { text: 'Vehicles', value: 'requiredVehicleCount' },
        { text: 'Vehicle Types', value: 'requiredVehicles' },
        { text: 'Due Today', value: 'tripDueToday' },
        { text: 'Subtotal', value: 'tripSubtotal' },
        { text: 'Fees', value: 'tripFees' },
        { text: 'Grand Total', value: 'tripTotal' },
      ],
      formattedData: [],
    }
  },
  mounted() {
    this.formatSummaryData()
  },
  methods: {
    formatSummaryData() {
      this.formattedData = this.trips.map((trip, tripIndex) => {
        const { passengerCount = 0, requiredDrivers = 0 } = trip
        const tripSubtotal = isNaN(this.subtotal(trip))
          ? 0
          : this.subtotal(trip)
        const tripFees = isNaN(this.processingFeeTotal(trip))
          ? 0
          : this.processingFeeTotal(trip)
        const requiredVehicles = this.getVehicleTypeNames(trip)
        const requiredVehicleCount = trip.requiredVehicles.reduce(
          (allVehicles, vehicle) =>
            (allVehicles += parseInt(vehicle.quantity || 0)),
          0
        )
        const tripTotal = tripSubtotal + tripFees
        const tripPaymentTypeId = trip.paymentType.id

        let tripDueToday = 0
        if (tripPaymentTypeId) {
          tripDueToday =
            tripPaymentTypeId === 1
              ? tripTotal
              : tripTotal * (trip.depositPercentage / 100)
        }
        return {
          tripIndex: tripIndex + 1,
          passengerCount,
          requiredDrivers,
          requiredVehicles,
          requiredVehicleCount,
          tripDueToday: currencyFilter(tripDueToday),
          tripSubtotal: currencyFilter(tripSubtotal),
          tripFees: currencyFilter(tripFees),
          tripTotal: currencyFilter(tripTotal),
        }
      })
    },
    subtotal(trip) {
      const chargeTotal = trip.charges.reduce(
        (total, charge) => total + parseFloat(charge.amount || 0),
        0
      )
      return chargeTotal
    },
    processingFeeTotal(trip) {
      const processingFeeCharge =
        trip.processingFeePercentage * 0.01 * this.subtotal(trip)
      trip.processingFeeCharge = processingFeeCharge
      return parseFloat(processingFeeCharge || 0)
    },
    getVehicleTypeNames(trip) {
      const vehicleTypes = {}
      trip.requiredVehicles.forEach((vehicle) => {
        const vehicleType = this.vehicleTypes.find(
          (type) => type.id === vehicle?.vehicleType?.id
        )
        if (vehicleType) {
          vehicleTypes[vehicleType.label] = true
        }
      })
      return Object.keys(vehicleTypes).join(', ')
    },
  },
}
</script>
