<template>
  <v-card flat class="skeleton-trip-collection-card">
    <v-card-text>
      <!-- <SkeletonBox type="header" />
      <br /> -->
      <div v-if="map">
        <SkeletonBox style="height: 150px" />
        <br />
      </div>
      <SkeletonBox type="text" />
      <br />
      <SkeletonBox style="height: 84px" />
      <br />
      <SkeletonBox style="height: 60px" />
      <br />
      <SkeletonBox type="text" />
      <br />
      <SkeletonBox style="height: 60px" />
      <br />
      <SkeletonBox type="text" />
      <br />
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    map: {
      type: Boolean,
    },
  },
}
</script>

<style lang="scss" scoped>
.skeleton-trip-collection-card {
  padding-top: 12px;
  padding-bottom: 12px;
}
.v-card {
  border: none !important;
}
</style>
