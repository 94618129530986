<template>
  <v-data-table :items="items" :headers="headers" v-bind="{ $scopedSlots }" />
</template>

<script>
export default {
  name: 'CTable',
  props: {
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
    headers: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
}
</script>

<style lang="scss">
@import '..//scss/variables';

.active-row {
  background-color: $secondary;
}

table.v-table thead th {
  padding: 0 6px !important;
}

.next-to-parent {
  position: absolute;
  top: 18px;
  left: 34px;
}

.parent-star {
  position: relative;
}

.cr-progress-bar {
  position: relative;
  height: 2px;
}

.v-list__tile__action {
  margin-top: 10px;
}

.status-filters {
  margin-bottom: 14px;
}

.status-filter-select {
  padding: 5px;
  margin-right: 10px;
  text-align: center;
  background-color: $gray-lighter;
  border-radius: 5px;
}

.status-filter-select:last-child {
  margin-right: 0;
}

.filter-control {
  margin-right: 40px;
}

.filter-control:last-child {
  margin-right: 0;
}

.padded-menu-item {
  padding-right: 6px;
  padding-left: 10px;

  span {
    margin-left: 4px;
  }
}

.v-tabs__slider.accent {
  background-color: $primary !important;
  border-color: $primary !important;
}

.header-default {
  text-align: left;
}

.header-center {
  text-align: center;
}

.data-default {
  text-align: left;
}

.data-center {
  text-align: center;
}

table.v-table thead td:not(:nth-child(1)),
table.v-table tbody td:not(:nth-child(1)),
table.v-table thead th:not(:nth-child(1)),
table.v-table tbody th:not(:nth-child(1)),
table.v-table thead td:first-child,
table.v-table tbody td:first-child,
table.v-table thead th:first-child,
table.v-table tbody th:first-child {
  padding: 0 6px !important;
}

// Table row hover
.theme--light.v-table tbody tr:hover:not(.v-datatable__expand-row) {
  background-color: $secondary;
}

th.active {
  background-color: $white !important;
}
</style>
