<template>
  <v-form ref="quote-footer-form" autocomplete="off">
    <v-layout column>
      <v-layout>
        <v-flex x12>
          <v-layout row wrap>
            <v-flex xs2>
              <div class="blue-bar" />
            </v-flex>
            <v-flex v-if="showMarketplaceFeatures" xs2>
              <div class="blue-bar" />
            </v-flex>
            <v-flex v-if="showMarketplaceFeatures" xs2>
              <div class="blue-bar" />
            </v-flex>
            <v-flex xs2>
              <div class="green-bar" />
            </v-flex>
            <v-flex v-if="isQuotesAutoSetPaymentEnabled" xs2>
              <div class="green-bar" />
            </v-flex>
            <v-spacer />
          </v-layout>
        </v-flex>
      </v-layout>
      <v-layout>
        <v-flex x12>
          <v-layout row wrap>
            <v-flex v-if="!isRA" xs2 info-padded>
              <v-layout column class="layout-height">
                <v-flex class="checkout-labels">
                  <div class="subtitle">Lead Source</div>
                  <div class="display-text">
                    {{ quote.leadSource ? quote.leadSource.label : '' }}
                  </div>
                </v-flex>
                <v-flex class="checkout-labels">
                  <div class="subtitle">Checkout Page</div>
                  <div class="display-text">
                    {{ quote.checkoutPage.label }}
                  </div>
                </v-flex>
              </v-layout>
            </v-flex>

            <v-flex v-if="showMarketplaceFeatures" xs2 info-padded>
              <v-layout column class="layout-height">
                <v-flex>
                  <div class="subtitle">Quote Expires Date</div>
                  <CRInput
                    id="quote-form-expiration-date"
                    v-model="expirationDate"
                    :disabled="isModeView"
                    :rules="
                      validate &&
                      op(quote, 'checkoutPage/label') === 'CharterUP'
                        ? [
                            isRequired(true, isNotEmpty, {
                              req: 'Quote Expiration Date is Required',
                              error: 'Quote Expiration Date is Required',
                            }),
                          ]
                        : []
                    "
                    type="date"
                    @input="setQuoteDateTimes"
                  />
                </v-flex>
                <v-flex>
                  <div class="subtitle">Quote Expires Time</div>
                  <CRInput
                    id="quote-form-expiration-time"
                    v-model="expirationTime"
                    :disabled="isModeView"
                    type="time"
                    :rules="
                      validate &&
                      op(quote, 'checkoutPage/label') === 'CharterUP'
                        ? [
                            isRequired(true, isNotEmpty, {
                              req: 'Quote Expiration Time is Required',
                              error: 'Quote Expiration Time is Required',
                            }),
                          ]
                        : []
                    "
                    @change="setQuoteDateTimes"
                  />
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex v-if="showMarketplaceFeatures" xs2 info-padded>
              <v-layout column class="layout-height">
                <v-flex>
                  <div class="subtitle">Quote Expires Timezone</div>
                  <CRSelect
                    id="quote-form-expiration-timezone"
                    v-model="expirationTimezone"
                    :items="timezoneOptions"
                    item-text="label"
                    item-value="id"
                    :disabled="isModeView"
                    return-object
                    :rules="
                      validate &&
                      op(quote, 'checkoutPage/label') === 'CharterUP'
                        ? [
                            isRequired(true, isNotEmpty, {
                              req: 'Quote Expiration Timezone is Required',
                              error: 'Quote Expiration Timezone is Required',
                            }),
                          ]
                        : []
                    "
                    @change="setQuoteTimezone"
                  />
                </v-flex>
              </v-layout>
            </v-flex>

            <v-flex xs2 style="max-width: 150px;">
              <v-layout column class="layout-height">
                <v-flex class="checkout-labels">
                  <div class="subtitle">Quote Total</div>
                  <div v-if="!shouldSplitTrip" class="display-text">
                    {{ recurringTotalDisplay }}
                    <br v-if="quote.currencyType !== 'USD'" />
                    <span v-if="quote.currencyType !== 'USD'">
                      ({{ recurringTotalExchangeDisplay }}
                      {{ quote.currencyType }})
                    </span>
                  </div>
                  <div v-else class="display-text">-- . --</div>
                </v-flex>
                <v-flex class="checkout-labels">
                  <div class="subtitle">Total Due Today</div>
                  <div class="display-text">
                    {{ recurringDepositDisplay }}
                    <br v-if="quote.currencyType !== 'USD'" />
                    <span v-if="quote.currencyType !== 'USD'">
                      ({{ recurringDepositExchangeDisplay }}
                      {{ quote.currencyType }})
                    </span>
                  </div>
                </v-flex>
              </v-layout>
            </v-flex>

            <v-flex v-if="isQuotesAutoSetPaymentEnabled" xs2 style="min-width: 20%;">
              <v-layout column class="layout-height">
                <v-flex class="checkout-labels">
                  <div class="subtitle">Payment Method</div>
                  <div class="display-text">
                    <div
                      v-for="(paymentSummary, paymentSummaryIndex) in paymentSummaries"
                      :key="paymentSummaryIndex"
                      class="margin-b-3"
                    >
                      <p class="trip-payment-method-title margin-b-0">
                        Trip {{ paymentSummaryIndex + 1 }}
                      </p>
                      <div class="trip-payment-method">
                        <p
                          v-if="paymentSummary.checkoutPaymentMethod"
                          class="payment-method-line margin-t-1 margin-b-0 margin-l-1"
                        >
                          {{ paymentSummary.isBalancePaymentMethodEnabled ? 'Deposit: ' : ''}}
                          {{ paymentSummary.checkoutPaymentMethod }}
                        </p>
                        <p
                          v-if="paymentSummary.balancePaymentMethod"
                          class="payment-method-line margin-t-1 margin-b-0 margin-l-1"
                        >
                          Balance: {{ paymentSummary.balancePaymentMethod }}
                        </p>
                        <p
                          v-if="paymentSummary.paymentType"
                          class="payment-method-line margin-t-1 margin-b-0 margin-l-1"
                        >
                          {{ paymentSummary.paymentType }}
                        </p>
                      </div>
                    </div>
                  </div>
                </v-flex>
              </v-layout>
            </v-flex>

            <v-spacer />

            <v-flex
              text-xs-right
              justify-center
              fill-height
              quote-totals-save-actions
            >
              <v-layout column class="layout-height">
                <v-flex>
                  <v-layout row>
                    <v-flex>
                      <v-spacer />
                    </v-flex>
                    <v-flex style="max-width: 160px">
                      <v-btn
                        v-if="!isModeView && quote.contractId"
                        id="quote-form-save-to-contract-button"
                        :loading="saveLoading"
                        :disabled="disableButtons"
                        class="btn-primaryaction"
                        block
                        @click="submitThisForm(true)"
                      >
                        Save to Contract
                      </v-btn>

                      <v-btn
                        v-if="!isModeView && !quote.contractId"
                        id="quote-form-save-for-later-button"
                        :loading="saveLoading"
                        :disabled="disableButtons"
                        class="btn-secondaryaction"
                        block
                        @click="submitThisForm(true)"
                      >
                        <span style="margin-right: 0.25em">Save for Later</span>
                        <!-- <span v-if="showMarketplaceFeatures">Quote</span>
                        <span v-else>for Later</span> -->
                      </v-btn>

                      <CRSidebarDialog v-model="saveAndConvertDialog">
                        <span slot="title">Convert Quote</span>
                        <div class="save-and-convert">
                          <div class="save-and-convert--content">
                            <p>
                              Are you sure you would like to convert this quote
                              to reservations? This will not notify the customer
                              and will only create reservations.
                            </p>
                          </div>
                          <div class="save-and-convert--button-spacer"></div>
                          <div
                            id="save-and-convert--submit-btn"
                            class="save-and-convert--submit-btn"
                            @click="saveAndConvert"
                          >
                            <span>Convert</span>
                          </div>
                        </div>
                      </CRSidebarDialog>

                      <v-btn
                        v-if="quote.isPreBooking"
                        id="quote-form-save-and-convert-button"
                        :loading="saveLoading"
                        :disabled="disableButtons"
                        class="btn-primaryaction"
                        block
                        @click="saveAndConvertDialog = true"
                      >
                        Save and Convert
                      </v-btn>
                      <v-btn
                        v-if="
                          !isModeView &&
                          !quote.contractId &&
                          !quote.isPreBooking
                        "
                        id="quote-form-save-and-send-button"
                        :loading="saveLoading"
                        block
                        class="btn-primaryaction"
                        :disabled="disableButtons || quote.soldOut"
                        @click="submitThisForm(false)"
                      >
                        Save and Send
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-layout>
  </v-form>
</template>

<script>
import { mapActions } from 'vuex'
import { currencyFilter } from '@/utils/currency'
import { isRequired, isNotEmpty } from '@/utils/validators'
import { mapGetters } from 'vuex'
import op from 'simple-object-path'
import { authComputed } from '@/state/helpers'
import { DateTime } from 'luxon'
import { EventBus } from '@/utils/event-bus'
import { PaymentStageKey, PaymentTypeId } from '@/utils/enum'
import { deepClone } from '@/utils/deepClone'
import { isBalancePaymentMethodEnabled } from '@/utils/quoteUtils'
import { isAnyTripStopDateInPast } from '@/utils/time'

export default {
  props: {
    quote: { type: Object, default: () => ({}) },
    total: { type: Number, default: 0 },
    recurringTotal: { type: Number, default: 0 },
    exchangeRate: { type: Number, default: 1 },
    requiredDeposit: { type: Number, default: 0 },
    mode: { type: String, default: 'view' },
    lockSaveButton: { type: Boolean },
    saveQuoteHandler: {
      type: Function,
      default: () => {
        return function () {}
      },
    },
    setDateTimes: {
      type: Function,
      default: () => {
        return function () {}
      },
    },
    showMarketplaceFeatures: {
      type: Boolean,
    },
    paymentTypes: {
      type: Array,
      default: () => [],
    },
    isEditingCustomer: {
      type: Boolean,
    },
  },
  data() {
    return {
      isNotEmpty,
      isRequired,
      model: null,
      validate: false,
      decisionDate: null,
      decisionTime: null,
      expirationDate: null,
      expirationTime: null,
      expirationTimezone: null,
      selectedMarkets: false,
      saveAndConvertDialog: false,
      timezoneOptions: [
        {
          id: 0,
          label: 'Eastern Time',
          zoneName: 'America/New_York',
        },
        {
          id: 1,
          label: 'Central Time',
          zoneName: 'America/Chicago',
        },
        {
          id: 2,
          label: 'Mountain Time',
          zoneName: 'America/Denver',
        },
        {
          id: 3,
          label: 'Pacific Time',
          zoneName: 'America/Los_Angeles',
        },
        {
          id: 4,
          label: 'Alaskan Time',
          zoneName: 'America/Anchorage',
        },
        {
          id: 5,
          label: 'Hawaiian Time',
          zoneName: 'Pacific/Honolulu',
        },
      ],
      submitting: false,
      paymentSummaries: [],
      userCreationInProgress: false,
    }
  },
  computed: {
    ...authComputed,
    ...mapGetters({
      shouldSplitTrip: 'quotes/getShouldSplitTrip',
    }),
    isModeView() {
      return this.mode === 'view'
    },
    recurringTotalDisplay() {
      return currencyFilter(this.recurringTotal)
    },
    recurringTotalExchangeDisplay() {
      return currencyFilter(this.recurringTotal * this.exchangeRate)
    },
    recurringDepositDisplay() {
      return currencyFilter(this.requiredDeposit)
    },
    recurringDepositExchangeDisplay() {
      return currencyFilter(this.requiredDeposit * this.exchangeRate)
    },
    isRAMarketplaceQuote() {
      return (this.isRA || this.isRAManager) && this.showMarketplaceFeatures
    },
    saveLoading() {
      return (
        this.submitting || this.lockSaveButton || this.userCreationInProgress
      )
    },
    disableButtons() {
      return this.submitting || this.userCreationInProgress
    },
    paymentMethodTypeIds() {
      return this.quote.trips.map((trip) => trip.paymentType.id)
    },
    isQuotesAutoSetPaymentEnabled() {
      const { getters = {} } = this.$store
      return !getters['systemParameters/isQuotesAutoSetPaymentDisabled']
    },
  },
  watch: {
    'quote.decisionDate': function (val) {
      this.decisionDate = val?.split('T')[0]
      this.decisionTime = val?.split('T')[1]?.split('.')[0]
    },
    'quote.expirationDate': function (val) {
      this.expirationDate = val?.split('T')[0]
      this.expirationTime = val?.split('T')[1]?.split('.')[0]
    },
    quote: {
      deep: true,
      handler() {
        Object.assign(this.$data, this.$options.data())
        this.init()
      },
    },
    paymentMethodTypeIds() {
      this.calculatePaymentSummaries()
    },
  },
  async mounted() {
    EventBus.$on('recalculate-payment-methods', () => {
      this.calculatePaymentSummaries()
    })
    EventBus.$on('user-creation-in-progress', (status) => {
      this.userCreationInProgress = status
    })
    this.init()
  },
  beforeDestroy() {
    EventBus.$off('recalculate-payment-methods')
    EventBus.$off('user-creation-in-progress')
  },
  methods: {
    ...mapActions({
      showAlert: 'app/showAlert',
    }),
    op,
    currencyFilter,
    init() {
      if (this.quote.decisionDate) {
        this.decisionDate = this.quote.decisionDate.split('T')[0]
        this.decisionTime = this.quote.decisionDate.split('T')[1].split('.')[0]
      }
      if (this.quote.expirationDate) {
        this.expirationDate = this.quote.expirationDate.split('T')[0]
        this.expirationTime = this.quote.expirationDate
          .split('T')[1]
          .split('.')[0]
      }
      if (this.quote.expirationTimezone) {
        this.expirationTimezone = this.timezoneOptions.find((option) => {
          return this.quote.expirationTimezone === option.zoneName
        })
      }
      this.calculatePaymentSummaries()
    },
    submitThisForm(shouldSend, saveAndConvert = false) {
      if (this.submitting) {
        return
      }
      this.submitting = true
      this.validate = true
      if (
        (this.quote.charterUpQuote &&
          !this.$refs['quote-footer-form'].validate()) ||
        this.userCreationInProgress ||
        this.isEditingCustomer
      ) {
        if (this.isEditingCustomer) {
          this.showAlert({
            message: 'Please save customer',
            type: 'error',
          })
        }
        this.submitting = false
        return
      }
      this.setQuoteDateTimes()
      this.saveQuoteHandler(
        shouldSend,
        this.selectedMarkets,
        saveAndConvert,
        this.quote.soldOut,
        this.quote.isPendingConfirmation,
        isAnyTripStopDateInPast(this.quote?.trips)
      )
      this.submitting = false
    },
    setQuoteDateTimes() {
      if (this.quote.isExpirationDateTimeAutoset == true) {
        this.$emit('set-is-expiration-autoset', false)
      }

      if (this.expirationDate && !this.expirationTime) {
        this.expirationTime = '12:00:00'
      } else if (this.expirationTime?.length === 5) {
        this.expirationTime = `${this.expirationTime}:00`
      }

      if (this.expirationDate?.length !== 10) {
        return
      }

      if (this.expirationDate && this.expirationTime) {
        const expirationTimezone =
          this.expirationTimezone?.zoneName || 'America/New_York'
        const expirationDateNoOffset = this.expirationDate
          ? this.expirationDate + 'T' + this.expirationTime + `.000`
          : null

        let offset =
          DateTime.fromISO(expirationDateNoOffset, {
            zone: expirationTimezone,
          }).offset / 60

        if (Math.abs(offset) < 10) {
          offset = `-0${Math.abs(offset)}`
        } else {
          offset = offset.toString()
        }
        const expirationDate = this.expirationDate
          ? expirationDateNoOffset + `${offset}00`
          : null

        const decisionDate = expirationDate

        this.setDateTimes({ decisionDate, expirationDate, expirationTimezone })
      }
    },
    setQuoteTimezone() {
      if (this.quote.isExpirationDateTimeAutoset) {
        if (this.expirationDate && this.expirationTime) {
          const expirationTimezone =
            this.expirationTimezone?.zoneName || 'America/New_York'
          const expirationDate =
            this.expirationDate + 'T' + this.expirationTime + `.000`

          let adjustedExpirationDate = DateTime.fromISO(expirationDate).setZone(
            expirationTimezone,
            {
              keepLocalTime: false,
            }
          )

          let localOffset =
            DateTime.fromISO(this.quote.expirationDate).offset / 60
          let newOffset = adjustedExpirationDate.offset / 60
          adjustedExpirationDate = DateTime.fromISO(
            this.quote.expirationDate
          ).plus({
            hours: parseInt(newOffset) - parseInt(localOffset),
          })

          adjustedExpirationDate = adjustedExpirationDate.toISO()

          this.expirationDate = adjustedExpirationDate.split('T')[0]
          this.expirationTime = adjustedExpirationDate
            .split('T')[1]
            .split('.')[0]

          const decisionDate = adjustedExpirationDate

          this.$emit('set-date-times', {
            decisionDate,
            adjustedExpirationDate,
            expirationTimezone,
          })
        }
      } else {
        this.setQuoteDateTimes()
      }
    },
    saveAndConvert() {
      this.saveAndConvertDialog = false
      this.submitThisForm(true, true)
    },
    paymentTypeFromId(id) {
      return this.paymentTypes.find((type) => type.id === id)
    },
    calculatePaymentSummaries() {
      this.paymentSummaries = []

      for (const trip of this.quote.trips) {
        const tripPaymentSummary = {}

        const isBalanceMethodsEnabled = isBalancePaymentMethodEnabled(trip)
        tripPaymentSummary.isBalancePaymentMethodEnabled = isBalanceMethodsEnabled

        const checkoutPaymentMethodSummary
          = this.getPaymentMethodSummary(trip, PaymentStageKey.Checkout)
        tripPaymentSummary.checkoutPaymentMethod = checkoutPaymentMethodSummary

        if (isBalanceMethodsEnabled) {
          const balancePaymentMethodSummary
            = this.getPaymentMethodSummary(trip, PaymentStageKey.RemainingBalance)
          tripPaymentSummary.balancePaymentMethod = balancePaymentMethodSummary
        }

        const paymentTypeSummary = this.getPaymentTypeSummary(trip)
        if (paymentTypeSummary) {
          tripPaymentSummary.paymentType = paymentTypeSummary
        }

        this.paymentSummaries.push(tripPaymentSummary)
      }
    },
    getPaymentMethodSummary(trip, paymentStage) {
      if (!trip.stagePaymentMethods) {
        return ''
      }

      let allowedPaymentMethods = []
      if (paymentStage === PaymentStageKey.Checkout) {
        allowedPaymentMethods = deepClone(trip.stagePaymentMethods.checkoutPaymentMethods)
      }
      if (paymentStage === PaymentStageKey.RemainingBalance) {
        allowedPaymentMethods = deepClone(trip.stagePaymentMethods.balancePaymentMethods)
      }

      if (allowedPaymentMethods) {
        return allowedPaymentMethods
          .filter((method) => method.isAllowed)
          .map((method) => {
            return this.getPaymentMethodLabel(trip, method.paymentMethodId)
          })
          .join(', ')
      }
      return ''
    },
    getPaymentTypeSummary(trip) {
      const paymentType = this.paymentTypeFromId(trip.paymentType.id)
      if (paymentType?.key === 'down_payment' || paymentType?.key == null) {
        return `${trip.depositPercentage}% Down`
      }

      return paymentType?.label || ''
    },
    getPaymentMethodLabel(trip, paymentMethodTypeId) {
      if (trip.paymentMethods) {
        const paymentMethod = trip.paymentMethods.find((method) =>
          method.paymentMethodTypeId === paymentMethodTypeId
        )
        return paymentMethod?.paymentMethodType?.label || ''
      }
      return ''
    },
  },
}
</script>
<style lang="scss" scoped>
.subtitle {
  color: $gray-medium-light;
}

.display-text {
  padding-top: 8px;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.35em;
  letter-spacing: normal;
}

.layout-height {
  height: 100%;
  padding: 15px;
}

.checkout-labels {
  min-height: 50%;
}

.blue-bar {
  width: 100%;
  height: 10px;
  background-color: $blue;
}

.dark-gray-bar {
  width: 100%;
  height: 10px;
  background-color: gray;
}

.green-bar {
  width: 100%;
  height: 10px;
  background-color: rgba(0, 128, 0, 0.5);
}

.form-options {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .decision-date {
    max-width: 156px;
    max-height: 45px;
    margin-top: 6px;
    margin-right: 8px;
  }
}

.switch-right {
  flex: 0 1 auto;
  justify-content: flex-end;
  padding-right: 1em;

  ::v-deep label {
    color: $blue;
  }
  // ::v-deep &__slot {
  //   justify-content: flex-end;
  //   padding-right: 1em;
  // }
}

.save-and-convert {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 500px;
  position: relative;

  .save-and-convert--content {
    flex: 1;
    padding: 24px 48px;
  }

  .save-and-convert--button-spacer {
    min-height: 71px;
    width: 100%;
  }

  .save-and-convert--submit-btn {
    display: flex;
    position: fixed;
    flex-direction: column;
    background: $primary;
    font-size: 18px;
    color: white;
    bottom: 0;
    width: inherit;
    height: 71px;
    padding: 24px 0;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
  }
}

.trip-payment-method-title {
    font-size: 16px;
    line-height: 1;
}

.trip-payment-method {
  border-left: 1px solid;
  .payment-method-line {
    font-size: 14px;
    line-height: 1;
  }
}
</style>
