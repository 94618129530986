<template>
  <span>
    <v-btn
      v-if="showClearButton"
      :id="`quote-form-clear-trip-name-button-${index}`"
      icon
      style="float: left; margin-right: 12px; margin-left: -4px"
      class="icon-button-edit"
      @click.stop="clearTripName"
    >
      <v-icon class="tab-icon">replay</v-icon>
    </v-btn>
    <div
      :id="`quote-form-name-editor-${index}`"
      :contenteditable="editing"
      style="margin-top: 2px; font-weight: 700; float: left"
      :style="showClearButton ? '' : 'padding-left: 16px;'"
      @keyup="handleKeyup"
      @keydown="resizeSlider"
    >
      {{ displayName }}
    </div>
    <v-btn
      v-if="showButtons"
      :id="`quote-form-edit-trip-name-button-${index}`"
      icon
      class="icon-button-edit"
      style="float: left; margin-left: 12px"
      @click.stop="editToggle"
    >
      <v-icon small class="tab-icon">
        {{ editing ? 'check' : 'create' }}
      </v-icon>
    </v-btn>
  </span>
</template>

<script>
export default {
  props: {
    trip: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    active: {
      type: Boolean,
      required: true,
    },
    mode: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      editing: false,
      name: null,
      oldName: null,
      inputField: null,
      autoSave: null,
      autoResize: null,
    }
  },
  computed: {
    isModeView() {
      return this.mode === 'view'
    },
    showButtons() {
      return this.active && !this.isModeView
    },
    showClearButton() {
      return this.showButtons && (this.trip.routeName || this.editing)
    },
    displayName() {
      return this.trip.routeName
        ? this.trip.routeName
        : `Trip ${this.index + 1}`
    },
  },
  watch: {
    active() {
      if (!this.active) {
        this.editing = false
      }
    },
  },
  mounted() {
    this.inputField = document.getElementById(
      `quote-form-name-editor-${this.index}`
    )
  },
  methods: {
    editToggle() {
      if (!this.editing) {
        this.oldName = this.inputField.textContent
        this.editing = true
        this.selectText()
      } else {
        this.saveName()
      }
    },
    async selectText() {
      // Wait for the input field to be rendered.
      // User can click the edit button before the input field is rendered.
      if (this.inputField == null) {
        return
      }

      await this.inputField.focus()
      if (document.body.createTextRange) {
        const range = document.body.createTextRange()
        range.moveToElementText(this.inputField)
        range.select()
      } else if (window.getSelection) {
        const selection = window.getSelection()
        const range = document.createRange()
        range.selectNodeContents(this.inputField)
        selection.removeAllRanges()
        selection.addRange(range)
      }
    },
    saveName() {
      let newName = this.inputField.textContent
      if (newName !== this.oldName && newName !== '') {
        newName = newName.trim()
        this.$emit('name-saved', newName)
      } else if (newName === '') {
        this.clearTripName()
      }
      this.oldName = null
      this.editing = false
    },
    clearTripName() {
      this.inputField.textContent = `Trip ${this.index + 1}`
      this.editing = false
      this.$emit('clear')
      this.resizeSlider()
    },
    handleKeyup(event) {
      clearTimeout(this.autoSave)
      clearTimeout(this.autoResize)
      if (this.editing) {
        this.autoSave = setTimeout(() => {
          this.saveName()
        }, 3000)
        this.autoResize = setTimeout(() => {
          this.resizeSlider()
        }, 25)
        event.preventDefault()
        if (event.keyCode === 13) {
          this.inputField.textContent = this.inputField.textContent.trim()
          this.saveName()
        }
      }
    },
    resizeSlider() {
      window.dispatchEvent(new Event('resize'))
    },
  },
}
</script>

<style lang="scss">
.quote-trip-tab {
  &:hover .icon-button-edit {
    visibility: visible;
  }
}
</style>
<style lang="scss" scoped>
[contenteditable] {
  outline: 0px solid transparent;
  &:focus {
    border-bottom: 2px solid $primary;
  }
}
.icon-button-edit {
  position: relative;
  right: 0;
  width: 8px;
  height: 8px;
  visibility: hidden;

  &::v-deep .v-icon {
    font-size: 16px;
  }
}
.tab-icon {
  margin-top: -2px;
}
</style>
