<template>
  <div class="map-container">
    <div v-show="!mapLoaded" class="sheet map-container">
      <v-layout row wrap>
        <v-flex xs12 text-xs-center>
          <h3>Loading Market Rates</h3>
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex xs12 text-xs-center>
          <v-progress-circular :size="50" color="primary" indeterminate />
        </v-flex>
      </v-layout>
    </div>
    <GmapMap
      v-show="mapLoaded"
      ref="gMap"
      :center="mapCenter"
      :options="{
        streetViewControl: false,
        fullscreenControl: false,
        mapTypeControl: false,
        styles: mapStyles,
      }"
      map-type-id="roadmap"
      style="width: 100%; height: 100%"
    />
  </div>
</template>
<script>
import { authComputed } from '@/state/helpers'
import { mapStyles } from '@/components/mapStyles'
import markets from '@/services/markets'
import { gmapApi } from 'vue2-google-maps'
import { currencyFilter } from '@/utils/currency'

export default {
  data() {
    return {
      markets: [],
      showMarketRatesMap: false,
      map: null,
      mapCenter: { lat: 10, lng: 10 },
      mapStyles,
      mapLoaded: false,
    }
  },
  computed: {
    ...authComputed,
    google: gmapApi,
  },
  async mounted() {
    const { company = {} } = this.currentUser || {}
    const marketsData = await markets.byCompanyId(company.companyId)
    this.markets = marketsData?.data || []
    await this.$gmapApiPromiseLazy()
    this.loadMap()
  },
  methods: {
    makeInfoWindow(market) {
      const { rates = [], name = '', notes = '' } = market

      const html = []

      html.push('<div class="sheet">')
      html.push(`<h3>${name}</h3>`)

      const marketRateTypes = rates
        .map((r) => r.market_rate_type)
        .reduce((prevMarketRateTypes, currentMarketRateType) => {
          prevMarketRateTypes = prevMarketRateTypes || {}
          prevMarketRateTypes[currentMarketRateType.id] = currentMarketRateType
          return prevMarketRateTypes
        }, {})

      const vehicleTypes = rates
        .map((r) => r.vehicle_type)
        .reduce((prevVehicleTypes, currentVehicleTypes) => {
          prevVehicleTypes = prevVehicleTypes || {}
          prevVehicleTypes[currentVehicleTypes.id] = currentVehicleTypes
          return prevVehicleTypes
        }, {})
      html.push('<table class="rate-table"><tr><th></th>')
      for (const marketRateType in marketRateTypes) {
        html.push(`<th>${marketRateTypes[marketRateType].label}</th>`)
      }
      html.push('</tr>')
      for (const vehicleType in vehicleTypes) {
        html.push('<tr>')
        const vt = vehicleTypes[vehicleType]
        html.push(`<th>${vt.label}</th>`)
        for (const marketRateType in marketRateTypes) {
          const mrt = marketRateTypes[marketRateType]
          const rateItem = rates.find(
            (rate) =>
              rate?.market_rate_type?.id === mrt.id &&
              rate?.vehicle_type?.id === vt.id
          )

          if (rateItem) {
            html.push(
              `<td>${currencyFilter(rateItem.low_rate)}-${currencyFilter(
                rateItem.high_rate
              )}</td>`
            )
          }
        }
        html.push('</tr>')
      }
      html.push('</tr></table>')

      html.push('<br/><hr style="height:1;"/>')
      html.push('<h3><small>Notes</small></h3>')
      html.push(`${notes || ''}`)

      html.push('</div>')

      const infoWindow = new google.maps.InfoWindow({
        content: html.join(''),
      })
      return infoWindow
    },
    loadMap() {
      const labels = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
      let labelIndex = 0
      if (this.markets.length === 0) {
        return
      }
      const infoWindows = []

      if (this.$refs.gMap == null) {
        return
      }

      this.$refs.gMap.$mapPromise.then((map) => {
        this.mapLoaded = true
        // map.fitBounds()
        // new google.maps.LatLng(0, 0)
        const bounds = new google.maps.LatLngBounds()
        this.markets.map((market) => {
          const infoWindow = this.makeInfoWindow(market)
          infoWindows.push(infoWindow)
          const marketLocation = {
            lat: market?.address?.lat,
            lng: market?.address?.lng,
          }
          const marker = new google.maps.Marker({
            position: marketLocation,
            label: labels[labelIndex++ % labels.length],
          })
          marker.addListener('click', () => {
            infoWindows.forEach((i) => i.close())
            infoWindow.open(map, marker)
          })
          marker.setMap(map)
          bounds.extend(marker.getPosition())
          return marker
        })

        setTimeout(() => {
          map.setZoom(6)
          map.fitBounds(bounds)
          this.mapCenter = bounds.getCenter()
        }, 0)
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.map-container {
  width: 100%;
  height: 800px;
}
</style>
<style lang="scss">
.rate-table {
  td {
    padding: 6px;
    text-align: center;
  }

  th {
    padding: 6px;
    text-align: right;
  }
}
</style>
