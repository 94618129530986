<template>
  <div style="position: relative; margin: 0; padding: 0">
    <v-container
      :class="$cr.breakpoint.xs ? 'pa-4' : ''"
      style="margin: 0; padding: 0"
    >
      <template v-if="!quote">
        <SkeletonBox style="height: 38px; width: 75%; margin: 48px auto 48px" />
        <SkeletonTripCollectionCard
          :map="false"
          style="max-width: 550px; margin: 0 auto"
        />
      </template>

      <template v-else>
        <h1
          style="
            text-align: center;
            font-size: 32px;
            font-weight: 600;
            margin: 0;
          "
        >
          {{ headerText }}
        </h1>
        <TripCard
          v-if="quote"
          itinerary
          customer-notes
          :quote-confirmation="true"
          :trips="quote.trips"
          :created-on="quote.createdOn"
          :style="`max-width: 500px; ${
            quote.trips.length > 1 ? 'margin: 0 auto' : 'margin: 20px auto;'
          }`"
        />
        <div style="height: 120px"></div>
      </template>
    </v-container>
    <div class="confirm-footer">
      <SkeletonBox
        v-if="!quote"
        style="height: 52px; width: 390px; margin: 48px auto 48px"
      />
      <v-btn
        v-else
        :loading="loading"
        color="primary"
        class="handle-quote-btn"
        @click="confirmQuote"
      >
        Looks great, let's get pricing!
      </v-btn>
    </div>
  </div>
</template>

<script>
import TripCard from '@/components/charterup/TripCard.vue'
import SkeletonTripCollectionCard from '@/components/SkeletonTripCollectionCard.vue'
import { confirmQuote } from '@/services/charterup/quotes'

export default {
  components: {
    TripCard,
    SkeletonTripCollectionCard,
  },
  props: {
    quote: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    headerText() {
      return this.quote
        ? `Hi ${this.quote.trips[0].customer.firstName}! How does this itinerary look?`
        : null
    },
  },
  watch: {
    $route: {
      deep: true,
      handler() {
        // store.dispatch('setShowFooter', false)
      },
    },
  },
  mounted() {
    // store.dispatch('setShowFooter', false)
  },
  methods: {
    async confirmQuote() {
      await confirmQuote(this.quote.quoteId)
      this.$router.push({ path: `/quotes/view/${this.quote.quoteId}/bids` })
    },
  },
}
</script>

<style lang="scss" scoped>
.confirm-footer {
  height: 120px;
  width: 100%;

  background-color: $white;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;

  .handle-quote-btn {
    text-transform: uppercase !important;
    padding: 25px 30px;
    color: white !important;
    font-size: 20px;
    font-weight: bold;
  }

  ::v-deep .v-btn {
    text-transform: uppercase;
    ::v-deep .v-btn__loader {
      .v-progress-circular {
        svg {
          color: white !important;
        }
      }
    }
  }
}
</style>
