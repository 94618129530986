<template>
  <v-container id="custom-invoice-form" fluid>
    <v-layout row>
      <v-flex xs6>
        <v-layout style="padding-right: 10px" column>
          <CRInput
            id="custom-invoice-to"
            v-model="customEmail.to"
            :rules="[
              isRequired(true, isNotEmpty, {
                req: 'Email(s) Required',
                error: 'Email(s) required and must be valid',
              }),
              isRequired(true, validateEmailList, {
                req: 'Email(s) Required',
                error: 'Email(s) must be valid',
              }),
            ]"
            type="text"
            floatinglabel="To"
            :placeholder="`john@gmail.com, susie@gmail.com`"
            solo
            flat
          />

          <CRInput
            v-if="shouldShowCheckoutText"
            id="custom-invoice-from"
            v-model="customEmail.from"
            :rules="[
              isRequired(true, validateEmail, {
                req: 'Email Required',
                error: 'Email must be valid',
              }),
            ]"
            type="text"
            floatinglabel="From (optional)"
            :placeholder="`john@gmail.com`"
            solo
            flat
          />

          <CRInput
            id="custom-invoice-subject"
            v-model="customEmail.subject"
            :rules="[
              isRequired(true, isNotEmpty, {
                req: 'Subject Is Required',
                error: 'Subject Is Required',
              }),
            ]"
            type="text"
            floatinglabel="Subject"
          />
          <v-layout column>
            <label>Opener</label>
            <v-textarea
              id="custom-invoice-opener"
              v-model="customEmail.opener"
              type="text"
              solo
              flat
              no-resize
            />
          </v-layout>
          <v-layout column>
            <label>Requested User Action</label>
            <v-textarea
              id="custom-invoice-requested"
              v-model="customEmail.requested"
              type="text"
              solo
              flat
              no-resize
            />
          </v-layout>
          <v-layout v-if="shouldShowCheckoutText" column>
            <label>Checkout Text</label>
            <v-textarea
              id="custom-invoice-checkout"
              v-model="customEmail.checkoutText"
              :rules="[
                isRequired(true, isNotEmpty, {
                  req: 'Checkout Text Is Required',
                  error: 'Checkout Text Is Required',
                }),
              ]"
              type="text"
              solo
              flat
              no-resize
            />
          </v-layout>
          <v-layout column>
            <label>Closing</label>
            <v-textarea
              id="custom-invoice-closing"
              v-model="customEmail.closing"
              type="text"
              solo
              no-resize
              flat
            />
          </v-layout>
          <CRInput
            v-if="isModeInvoice ? true : row.attachPdfToEmail ? true : false"
            id="custom-invoice-attachment"
            v-model="customEmail.attachment"
            type="text"
            floatinglabel="Attachment Name"
            placeholder=""
          />
        </v-layout>
      </v-flex>
      <v-flex xs6>
        <v-layout style="padding-left: 10px; height: 100%" column>
          <div style="margin-bottom: 4px">Email Preview</div>
          <v-layout class="email-preview" column>
            <v-layout class="subject-line" row>
              <div class="preview-label">To:</div>
              <div>{{ customEmail.to }}</div>
            </v-layout>
            <v-layout v-if="customEmail.from" class="subject-line" row>
              <div class="preview-label">From:</div>
              <div>{{ customEmail.from }}</div>
            </v-layout>
            <v-layout class="subject-line" row>
              <div class="preview-label">Subject:</div>
              <div>{{ customEmail.subject }}</div>
            </v-layout>
            <div style="margin-top: 20px">
              <pre class="preview">{{ emailPreview }}</pre>
              <div v-if="shouldShowCheckoutText" style="margin-bottom: 20px">
                <a :href="checkoutDomain" target="_blank">
                  <strong class="link">{{ customEmail.checkoutText }}</strong>
                </a>
              </div>
              <pre class="preview">{{ closingPreview }}</pre>
            </div>
          </v-layout>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import invoices from '@/services/invoices'
import { authComputed } from '@/state/helpers'
import { baseUrl } from '@/utils/env'
import { mapActions } from 'vuex'
import {
  isNotEmpty,
  isRequired,
  validateEmail,
  validateEmailList,
} from '@/utils/validators'

export default {
  props: {
    model: {
      type: Object,
      default: () => ({}),
    },
    cb: {
      type: Function,
      default: () => {},
    },
    row: {
      type: Object,
      default: () => ({}),
    },
    rows: {
      type: Array,
      default: () => [],
    },
    mode: {
      type: String,
      default: 'invoice',
    },
    edit: {
      type: Boolean,
      default: false,
    },
    checkoutDomain: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      customEmail: {
        to: null,
        subject: null,
        opener: null,
        requested: null,
        closing: null,
        attachment: null,
        checkoutText: null,
      },
      placeholders: {
        to: '',
        subject: '',
        opener: '',
        requested: '',
        closing: '',
      },
      emailPreview: '',
      sendEmail: true,
      closingPreview: '',
      customizeEmail: false,
    }
  },
  computed: {
    ...authComputed,
    shouldShowCheckoutText() {
      return (
        (this.isModeQuote || this.isModeContract) && this.row.allowEcheckout
      )
    },
    isModeInvoice() {
      return this.mode === 'invoice'
    },
    isModeQuote() {
      return this.mode === 'quote'
    },
    isModeContract() {
      return this.mode === 'contract'
    },
  },
  watch: {
    customEmail: {
      deep: true,
      handler() {
        this.updatePreview()
      },
    },
    'row.customerEmail': {
      deep: true,
      handler() {
        this.updateTo()
        this.initializeText()
      },
    },
    'row.allowEcheckout': {
      deep: true,
      handler() {
        this.initializeText()
      },
    },
    'row.customer.email': {
      deep: true,
      handler() {
        this.updateTo()
        this.initializeText()
      },
    },
  },
  async mounted() {
    await this.initializeText()

    this.updatePreview(true)
  },
  methods: {
    isNotEmpty,
    isRequired,
    validateEmail,
    validateEmailList,
    ...mapActions({ showAlert: 'app/showAlert' }),
    updateTo() {
      this.customEmail.to =
        this.row.customerEmail ||
        (this.row.customer && this.row.customer.email) ||
        this.customEmail.to
    },
    async initializeText() {
      let customizations
      const defaultCall = {
        to: null,
        subject: null,
        opener: null,
        requested: null,
        closing: null,
        attachment: null,
        checkoutText: null,
      }

      if (this.isModeInvoice) {
        customizations = await invoices
          .getEmailCustomizations()
          .then((data) => data.data || defaultCall)
      } else if (this.isModeContract) {
        return
      } else {
        customizations = await this.$store
          .dispatch('quotes/getEmailCustomizations')
          .then((data) => {
            return data.data?.emailCustomizationDTO
              ? data.data?.emailCustomizationDTO
              : defaultCall
          })
      }

      this.customEmail.closing =
        customizations.closing ||
        'We value your business and look forward to providing you with quality service.\n\nThanks,\n\n' +
          this.currentUser.companyName

      if (this.isModeInvoice) {
        this.customEmail.to = this.row.customerEmail || ''
        this.customEmail.subject =
          customizations.subject ||
          `Your Invoice from ${this.currentUser.companyName} is available`
        this.customEmail.opener =
          customizations.opener ||
          `Hi ${
            this.row.customerName.split(' ')[0] || 'Sample Name'
          },\n\nThank you for booking your trip with ${
            this.currentUser.companyName
          }`
        this.customEmail.requested =
          customizations.requestedUserAction ||
          'Please fill out the invoice attached to this email and return to us promptly.'
      }

      if (this.isModeContract) {
        this.customEmail.to = this.row.customer.email || ''
        this.customEmail.subject =
          customizations.subject || `Check out your shuttle quote!`
        this.customEmail.opener =
          customizations.opener ||
          `Hi ${
            this.row.customer.firstName || 'Sample Name'
          },\n\nLorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur gravida ante vel lorem commodo, in convallis elit sagittis. Pellentesque habitant morbi tristique senectus et netus.`
        this.customEmail.requested =
          customizations.requestedUserAction ||
          'Nam eleifend nulla sed risus auctor volutpat. Sed tempus, ligula elementum convallis ultrices,nulla odio iaculis lorem, sit amet accumsan odio ligula quis est. Cras sit amet ligula sed eros lacinia dignissim eget in dui. Vestibulum volutpat a purus posuere lacinia. Aliquam malesuada odio a quam egestas ultricies. Integer nec.'

        if (this.row.allowEcheckout) {
          this.customEmail.checkoutText =
            customizations.checkoutText || 'View my shuttle quote'
        }
      }

      if (this.isModeQuote) {
        this.customEmail.to =
          (this.row.customer && this.row.customer.email) || ''

        if (this.row.allowEcheckout) {
          this.customEmail.requested =
            customizations.requestedUserAction ||
            'Please find the link to your quote below to complete your booking.'
          this.customEmail.checkoutText =
            customizations.checkoutText || 'View My Quote'
        } else {
          this.customEmail.requested =
            customizations.requestedUserAction ||
            'Please find your quote attached to this email in order to complete your booking.'
        }

        if (this.edit) {
          this.customEmail.subject =
            customizations.subject ||
            `Your ${this.currentUser.companyName} Quote is Updated`
          this.customEmail.opener =
            customizations.opener ||
            `Hi ${this.row.customer.firstName},\n\nYour ${this.currentUser.companyName} Quote has been modified, please review the changes.`
        } else {
          this.customEmail.subject =
            customizations.subject ||
            `Your ${this.currentUser.companyName} Quote is ready`
          this.customEmail.opener =
            customizations.opener ||
            `Hi ${
              this.row.customer.firstName || 'Sample Name'
            },\n\nThank you for requesting a quote from ${
              this.currentUser.companyName
            }`
        }
      }
    },
    updatePreview(init) {
      let opts = ['opener', 'requested']
      this.emailPreview = ''
      opts.forEach((term) => {
        if (
          typeof this.customEmail[term] === 'string' &&
          this.customEmail[term].length > 0
        ) {
          this.emailPreview += this.customEmail[term] + '\n \n'
        } else {
          this.emailPreview += this.placeholders[term] + '\n \n'
        }
      })

      this.closingPreview = this.customEmail.closing

      if (!init) {
        const changes = JSON.parse(JSON.stringify(this.customEmail))
        changes.requestedUserAction = changes.requested
        this.$emit('email-change', changes)
      }
    },
    async submit() {
      this.loading = true
      await this.$forceUpdate()
      const isValid = await this.$refs['invoice-form'].validate()
      if (!isValid) {
        this.loading = false
      }

      if (this.sendEmail) {
        await invoices.sendInvoiceEmail(
          Object.keys(this.row).length
            ? [this.row.reservationId]
            : this.rows.map((item) => item.item.reservationId)
        )
      }

      window.open(
        `https://${baseUrl(
          'pdf'
        )}/pdf/invoice/reservations?reservationHashes=${encodeURIComponent(
          Object.keys(this.row).length
            ? this.row.hash
            : this.rows.map((item) => item.item.hash).join(',')
        )}`
      )
      this.showAlert({
        message: 'Invoice successfully generated!',
      })
      this.loading = false
      this.cancel()
    },
  },
}
</script>
<style lang="scss" scoped>
.email-box {
  padding: 20px;
}

.preview {
  white-space: pre-wrap !important;
  white-space: -moz-pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word !important;
  font-family: 'Lato', sans-serif;
}

.link {
  color: $primary;
  line-height: 14px;
  font-size: 14px;
}

.email-preview {
  border: 1px solid $border-gray;
  border-radius: 5px;
  padding: 20px;

  .preview-label {
    opacity: 0.5;
    color: $gray-base;
    font-family: Lato;
    margin-right: 4px;
  }

  .subject-line {
    border-bottom: 1px solid $border-gray;
    min-height: 40px;
    max-height: 40px;
    padding: 0px 0px 5px 0px;
    align-items: center;
  }

  .preview-box {
    height: 100%;
    box-sizing: border-box;
  }
}

::v-deep {
  .cr-input {
    label {
      margin-bottom: 4px;
    }
  }

  .preview-box {
    .v-input__control {
      min-height: 42px !important;

      > .v-input__slot {
        background-color: unset !important;
      }
    }
  }
}

#custom-invoice-form {
  padding: 0;
  margin-top: 18px;
  margin-bottom: 18px;
}
</style>
