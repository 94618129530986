import { render, staticRenderFns } from "./CustomizeEmailInvoice.vue?vue&type=template&id=3ee7476a&scoped=true"
import script from "./CustomizeEmailInvoice.vue?vue&type=script&lang=js"
export * from "./CustomizeEmailInvoice.vue?vue&type=script&lang=js"
import style0 from "./CustomizeEmailInvoice.vue?vue&type=style&index=0&id=3ee7476a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ee7476a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VContainer,VFlex,VLayout,VTextarea})
