
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { MarketRateType, TripCharge, TripChargeType } from '@/models/dto'
import { isMarketplaceQuote } from '@/utils/quoteUtils'
import { deepClone } from '@/utils/deepClone'
import { EventBus } from '@/utils/event-bus'
import { ChargeTypeId } from '@/utils/enum'
import {
  isNotEmpty,
  isNumber,
  isRequired,
  validateGreaterThanZero,
  validateLessThanZero,
  validateTripFormGroups,
} from '@/utils/validators'

const DEFAULT_MARKUP_PERCENT = 0
const DEFAULT_DISCOUNT_PERCENT = 5

@Component
export default class QuoteFormTripPaymentAdjustments extends Vue {
  @Prop({ type: Object, required: true }) readonly tripData: any
  @Prop({ type: Object, required: true }) readonly quote: any
  @Prop({ type: String, default: '' }) readonly mode: string
  @Prop({ type: Number, required: true }) readonly tripIndex: number
  @Prop({ type: Boolean, default: false }) readonly canOpenCharterUpQuote: boolean
  @Prop({ type: Number, default: null }) readonly marketplaceBaseAmount: number
  @Prop({ type: String, default: '' }) readonly validationKey: string
  @Prop({ type: Array, default: () => [] }) readonly rateTypes: MarketRateType[]
  @Prop({ type: Array, default: () => [] }) readonly chargeTypes: TripChargeType[]

  formSubmitted: boolean = false
  marketplaceDiscountAdded: boolean = false
  marketplaceMarkupAdded: boolean = false
  discountDebounce: NodeJS.Timeout = null
  markupDebounce: NodeJS.Timeout = null
  processingFeeDebounce: NodeJS.Timeout = null

  get isModeView(): boolean {
    return this.mode === 'view'
  }

  get isModeAdd(): boolean {
    return this.mode === 'add'
  }

  get isMarketplaceQuote(): boolean {
    return isMarketplaceQuote(this.quote)
  }

  get currentTrip(): any {
    return this.$store.getters['quoteForm/getCurrentTrip']
  }

  get charges(): any[] {
    return this.tripData?.charges || this.currentTrip?.charges
  }

  get rates(): any[] {
    return this.tripData?.rates || this.currentTrip?.rates
  }

  get processingFeePercentage(): number {
    if (
      this.tripData?.processingFeePercentage === null ||
      this.tripData?.processingFeePercentage === undefined
    ) {
      return this.currentTrip?.processingFeePercentage
    }
    return this.tripData?.processingFeePercentage
  }

  get hasManagerOverride(): boolean {
    return this.$store.getters['auth/hasManagerOverride']
  }

  get hasMarketplaceMarkup(): boolean {
    return (
      this.marketplaceMarkupAdded ||
      this.tripData.marketplaceMarkupPercent > 0
    )
  }

  get hasMarketplaceDiscount(): boolean {
    return (
      this.marketplaceDiscountAdded ||
      this.tripData.marketplaceDiscountPercent > 0
    )
  }

  get chargeTypesForDisplay(): TripChargeType[] {
    return this.chargeTypes?.filter((chargeType) => !chargeType.hidden)
  }

  get hasTripVehicleGroups(): boolean {
    return this.currentTrip?.tripVehicleGroups?.length > 0
  }

  @Watch('tripData.marketplaceMarkupPercent')
  onMarketplaceMarkupPercentChanged(): void {
    if (this.tripData.marketplaceMarkupPercent > 0) {
      this.marketplaceMarkupAdded = true
    }
  }

  @Watch('tripData.marketplaceDiscountPercent')
  onMarketplaceDiscountPercentChanged(): void {
    if (this.tripData.marketplaceDiscountPercent > 0) {
      this.marketplaceDiscountAdded = true
    }
  }

  @Watch('validationKey')
  onValidationKeyChanged(): void {
    this.validateForms()
  }

  validateForms(): void {
    const formNames = [
      'trip-rate-detail-form',
      'trip-charge-detail-form',
      'trip-processing-fee-charge-detail-form',
    ]
    validateTripFormGroups.call(this, formNames, 'payment')
  }

  setRateType(rateIndex: number, rateTypeId: number): void {
    const updatedRates = deepClone(this.rates)
    const updatedRateType = this.rateTypes.find(
      (type) => type.id === rateTypeId
    )
    if (!updatedRates[rateIndex] || !updatedRateType) {
      return
    }
    updatedRates[rateIndex].rateType = deepClone(updatedRateType)
    this.setUpdatedRates(updatedRates)
    EventBus.$emit('set-rate-type-id', rateIndex, rateTypeId)
  }

  setRateAmount(rateIndex: number, amount: number): void {
    const updatedRates = deepClone(this.rates)
    if (!updatedRates[rateIndex]) {
      return
    }
    updatedRates[rateIndex].amount = amount
    this.setUpdatedRates(updatedRates)
    EventBus.$emit('set-rate-amount', rateIndex, amount)
  }

  addRate(): void {
    if (this.hasTripVehicleGroups) {
      EventBus.$emit('add-rate')
    } else {
      const updatedRates = deepClone(this.rates)
      updatedRates.push({ rateType: {}, amount: undefined })
      this.setUpdatedRates(updatedRates)
    }
  }

  removeRate(rateIndex: number): void {
    if (this.hasTripVehicleGroups) {
      EventBus.$emit('remove-rate', rateIndex)
    } else {
      const updatedRates = deepClone(this.rates)
      if (!updatedRates[rateIndex]) {
        return
      }
      updatedRates.splice(rateIndex, 1)
      this.setUpdatedRates(updatedRates)
    }
  }

  setUpdatedRates(rates: any[]): void {
    const updatedTripData = {
      ...this.tripData,
      rates,
    }
    EventBus.$emit('shallow-merge-trip-data', this.tripIndex, updatedTripData)
  }

  setChargeType(chargeIndex: number, chargeTypeId: number): void {
    const updatedCharges = deepClone(this.charges)
    const updatedChargeType = this.chargeTypes.find(
      (type) => type.id === chargeTypeId
    )
    if (!updatedCharges[chargeIndex] || !updatedChargeType) {
      return
    }
    updatedCharges[chargeIndex].chargeType = deepClone(updatedChargeType)
    this.setUpdatedCharges(updatedCharges)
    EventBus.$emit('set-charge-type-id', chargeIndex, chargeTypeId)
  }

  setChargeAmount(chargeIndex: number, amount: number): void {
    const updatedCharges = deepClone(this.charges)
    if (!updatedCharges[chargeIndex]) {
      return
    }
    updatedCharges[chargeIndex].amount = amount
    this.setUpdatedCharges(updatedCharges)
    EventBus.$emit('set-charge-amount', chargeIndex, amount)
  }

  addCharge(): void {
    if (this.hasTripVehicleGroups) {
      EventBus.$emit('add-charge')
    } else {
      const updatedCharges = deepClone(this.charges)
      updatedCharges.push({ chargeType: {}, amount: undefined })
      this.setUpdatedCharges(updatedCharges)
    }
  }

  removeCharge(chargeIndex: number): void {
    if (this.hasTripVehicleGroups) {
      EventBus.$emit('remove-charge', chargeIndex)
    } else {
      const updatedCharges = deepClone(this.charges)
      if (!updatedCharges[chargeIndex]) {
        return
      }
      updatedCharges.splice(chargeIndex, 1)
      this.setUpdatedCharges(updatedCharges)
    }
  }

  isChargeInputDisabled(charge: any): boolean {
    return this.isModeView || (this.isMarketplaceQuote && this.isBaseFare(charge))
  }

  setUpdatedCharges(charges: any[]): void {
    const updatedTripData = {
      ...this.tripData,
      charges,
    }
    EventBus.$emit('shallow-merge-trip-data', this.tripIndex, updatedTripData)
  }

  addMarketPlaceMarkup(): void {
    this.marketplaceMarkupAdded = true
    const updatedTripData = {
      ...this.tripData,
      marketplaceMarkupPercent: DEFAULT_MARKUP_PERCENT,
    }
    EventBus.$emit('shallow-merge-trip-data', this.tripIndex, updatedTripData)
  }

  removeMarketplaceMarkup(): void {
    this.marketplaceMarkupAdded = false
    const updatedTripData = {
      ...this.tripData,
      marketplaceMarkupPercent: 0,
      marketplaceMarkupCharge: 0,
    }
    EventBus.$emit('shallow-merge-trip-data', this.tripIndex, updatedTripData)
  }

  addMarketPlaceDiscount(): void {
    this.marketplaceDiscountAdded = true
    const updatedTripData = {
      ...this.tripData,
      marketplaceDiscountPercent: DEFAULT_DISCOUNT_PERCENT,
    }
    EventBus.$emit('shallow-merge-trip-data', this.tripIndex, updatedTripData)
  }

  removeMarketplaceDiscount(): void {
    this.marketplaceDiscountAdded = false
    const updatedTripData = {
      ...this.tripData,
      marketplaceDiscountPercent: 0,
      marketplaceDiscountCharge: 0,
    }
    EventBus.$emit('shallow-merge-trip-data', this.tripIndex, updatedTripData)
  }

  setMarketplaceMarkupPercent(percent: number): void {
    const updatedTripData = {
      ...this.tripData,
      marketplaceMarkupPercent: this.constrainPercent(percent),
    }
    EventBus.$emit('shallow-merge-trip-data', this.tripIndex, updatedTripData)
  }

  setMarketplaceDiscountPercent(percent: number): void {
    const updatedTripData = {
      ...this.tripData,
      marketplaceDiscountPercent: this.constrainPercent(percent),
    }
    EventBus.$emit('shallow-merge-trip-data', this.tripIndex, updatedTripData)
  }

  setProcessingFeePercent(percent: number): void {
    const updatedTripData = {
      ...this.tripData,
      processingFeePercentage: this.constrainPercent(percent),
    }
    EventBus.$emit('shallow-merge-trip-data', this.tripIndex, updatedTripData)
  }

  constrainPercent(percent: number): number {
    const lowerBound = Math.max(0, Math.round(percent))
    return Math.min(100, lowerBound)
  }

  isBaseFare(charge: TripCharge): boolean {
    return charge?.chargeType?.id === ChargeTypeId.BaseFare
  }

  isDiscount(charge: TripCharge): boolean {
    return charge?.chargeType?.id === ChargeTypeId.Discount
  }

  isRevertToPricingTableEnabled(charge: TripCharge): boolean {
    return !this.isModeView &&
      this.isBaseFare(charge) &&
      ((!this.isMarketplaceQuote && this.canOpenCharterUpQuote) ||
        (this.isMarketplaceQuote && this.hasManagerOverride))
  }

  notEmptyValidator(message: string): Function[] {
    return this.formSubmitted
      ? [isRequired(true, isNotEmpty, { req: message, error: message })]
      : []
  }

  rateAmountValidator(amount: number): Function[] {
    const message = 'Rate amount is required'
    return this.formSubmitted
      ? [isRequired(
          true,
          () => validateGreaterThanZero(amount),
          { req: message, error: message }
        )]
      : []
  }

  chargeAmountValidator(amount: number): Function[] {
    const message = 'Charge amount is required'
    return this.formSubmitted
      ? [isRequired(
          true,
          () => validateGreaterThanZero(amount) ||
            this.quote.isPreBooking,
          { req: message, error: message }
        )]
      : []
  }

  discountAmountValidator(amount: number): Function[] {
    const message = 'Discount amount must be negative value'
    return this.formSubmitted
      ? [isRequired(
          true,
          () => validateLessThanZero(amount),
          { req: message, error: message }
        )]
      : []
  }

  processingFeePercentValidator(): Function[] {
    const message = 'Processing fee is required'
    return this.formSubmitted
      ? [isRequired(
          true,
          () => isNumber(this.processingFeePercentage),
          { req: message, error: message }
        )]
      : []
  }
}
